import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "C:/Users/User2/Documents/flits-docz/node_modules/gatsby-theme-docz/src/base/Layout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1 {...{
      "id": "bank-or-cash-transaction"
    }}>{`Bank or Cash Transaction`}</h1>
    <p>{`Secara umum Dokumen Transaksi Bank atau Kas ini digunakan untuk melakukan transaksi penerimaan atau pengeluaran uang dari dan atau ke perusahaan. Pada Flits untuk Transaksi Bank atau Kas ini dibedakan dalam 6 (enam) menu berbeda yakni : Receive Payment, PayBill, Driver Settlement, Receive Money, Send Money dan Internal Transfer. `}</p>
    <p>{`Kendati dibedakan menjadi beberapa menu, tetapi pada dasarnya semua menu tersebut memiliki fitur dan struktur yang sama pada bagian List Transaksinya, adalah sebagai berikut`}</p>
    <img src='/public/img/BoC Trx/List BoC Trx.png' />
 
Tampilan List Customer Invoice terbagi menjadi dua bagian yaitu :
    <h4 {...{
      "id": "tombol-menu-pada-list-boc-transaction--terdiri-dari-"
    }}>{`Tombol menu pada List BoC Transaction , terdiri dari :`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Buat`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tombol untuk membuat dokumen Invoice baru`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Import`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tombol untuk menginputkan dokumen SO secara massal dalam sekali import`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Filter`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Terdapat dua jenis filter yaitu by Status dan by Periode`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Search bar`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field yang dapat digunakan untuk melakukan pencarian terhadap dokumen yang diinginkan`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Atur Halaman`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Navigasi Next or Previous Page`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "list-boc-transaction-detail-terdiri-dari-"
    }}>{`List BoC Transaction Detail, terdiri dari :`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Payment#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Nomor Dokumen Transaksi (Menggunakan prefiks PM__)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl Dibuat`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tanggal dibuatnya dokumen pada sistem`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl Pembayaran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Tanggal diterbitkannya dokumen BoC Transaction`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Memo / Keterangan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Catatan untuk setiap dokumen BoC Transaction`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menunjukkan Jumlah pembayaran pada transaksi tersebut`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Status`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Terdiri dari tiga status, yakni :`}<ul><li><strong parentName="td">{`Draft`}</strong><div>{`Dokumen bisa dilakukan perubahan`}</div></li><li><strong parentName="td">{`Done`}</strong><div>{`Dokumen yang sudah dilakukan Approval (Telah terjadi perpindahan Uang dari dan atau ke perusahaan)`}</div></li><li><strong parentName="td">{`Cancelled`}</strong><div>{`Dokumen dibatalkan setelah dilakukan Approval`}</div></li></ul></td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "macam-macam-boc-transaction"
    }}>{`Macam-macam BoC Transaction`}</h3>
    <h2 {...{
      "id": "receive-payment-terima-pembayaran"
    }}>{`Receive Payment (Terima Pembayaran)`}</h2>
    <p>{`Receive  Payment adalah dokumen untuk melakukan pembayaran atas Customer Invoice yang statusnya “Open”. Walaupun dokumen ini merujuk pada Customer Invoice namun untuk pembayarannya tetap dilakukan pada masing-masing SO.`}</p>
    <h3 {...{
      "id": "formulir-terima-pembayaran"
    }}>{`Formulir Terima Pembayaran`}</h3>
    <img src='/public/img/BoC Trx/Form Receive Payment.png' />
    <h3 {...{
      "id": "kolom-kolom-pada-terima-pembayaran"
    }}>{`Kolom-kolom Pada Terima Pembayaran`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Customer`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field Pilih Nama Customer`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl. Pembayaran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk tanggal Dokumen Terima Pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Setor Ke`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field Pilih Bank/Kas yang digunakan untuk tarnsaksi`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Nomor Referensi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan nomor referensi dokumen lain`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "detail-tabel-customer-invoice"
    }}>{`Detail Tabel Customer Invoice`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Invoice#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk menampilkan Nomor Invoice yang akan dilakukan pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SO#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk menampilkan Nomor SO yang akan dilakukan pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl Jatuh Tempo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menampilkan Tanggal Jatuh Tempo masing-masing Invoice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sisa Tagihan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menampilkan Nominal harga yang belum dibayarkan untuk masing-masing SO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pembayaran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Memasukkan Jumlah pembayaran terhadap masing-masing SO (Nominal pembayaran tidak boleh lebih dari sisa tagihan)`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "informasi-lainnya-pada-customer-invoice"
    }}>{`Informasi Lainnya Pada Customer Invoice`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Memo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambah keterangan atau catatan pada Invoice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lampiran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambahkan file Attachment dalam dokumen Terima Pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Log`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mencatat setiap aktivitas pada dokumen Terima Pembayaran`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "membuat-dokumen-terima-pembayaran"
    }}>{`Membuat Dokumen Terima Pembayaran`}</h2>
    <p>{`Dokumen Terima Pembayaran dapat dibuat melalui dua cara :`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Melalui Invoice yang akan dilakukan pembayaran, berikut merupakan langkah-langkahnya`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Masuk ke List Customer Invoice dengan Klik Piutang Pada Side Menu > Customer Invoice`}</li>
          <li parentName="ul">{`Cari Customer Invoice yang akan dilakukan pembayaran (Invoice Harus berstatus Open)`}</li>
          <li parentName="ul">{`Pada Detail Invoice klik tombol Submit Payment`}</li>
          <li parentName="ul">{`Halaman akan terdireksi ke Halaman pembuatan dokumen Receive Payment > Masukkan Jumlah pembayarannya untuk setiap SO`}</li>
          <li parentName="ul">{`Jika sudah isi setiap field > klik tombol Save`}</li>
          <li parentName="ul">{`Untuk menuntaskan pembayaran dapat melakukan Konfirmasi Dokumen dengan Klik Tombol Confirm`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Melalui Create manual dari halaman List Terima Pembayaran, berikut merupakan langkah-langkahnya :`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Masuk ke List Terima Pembayaran dengan Klik Piutang Pada Side Menu > Terima Pembayaran > Pada List Terima Pembayaran Klik Tombol “Buat”`}</li>
          <li parentName="ul">{`Isi Formulir Terima Pembayaran seperti gambar berikut :`}
            <ol type="a">
              <li>Pilih Nama Customer yang akan melakukan pembayaran (Ketika memilih Customer akan muncul pop up yang berisi list invoice yang dapat dilakukan pembayaran)</li>
              <li>Pilih Invoice yang akan dibayar oleh Customer</li>
              <li>Atur Tanggal diterbitkannya dokumen Terima Pembayaran</li>
              <li>Pilih Bank/Kas pada field Setor ke</li>
              <li>Masukkan Jumlah Pembayaran untuk setiap SO yang ada </li>
              <li>Dalam satu dokumen Terima Pembayaran dapat mengandung lebih dari satu Invoice, sehingga bisa klik tombol “Tambah Item” untuk menambah Invoice yang lain</li>
              <li>Tambahkan file Attachment apabila diperlukan</li>
            </ol>
          </li>
        </ul>
        <img src='/public/img/BoC Trx/Bikin Receive Payment.png' />
        <ul parentName="li">
          <li parentName="ul">{`Setelah semua field diisi, klik tombol Save untuk menyimpan dokumen Terima Pembayaran yang telah diisi sebelumnya. Pada saat ini status Dokumen adalah “Draft”. (Dokumen masih perlu Approval untuk dapat dilanjutkan ke langkah berikutnya)`}</li>
          <li parentName="ul">{`Untuk melakukan Approval user dapat klik tombol Confirm pada halaman detail dokumen. Dengan ini status dokumen Terima Pembayaran menjadi “Done” yang artinya Pembayaran telah berhasil dilakukan.`}</li>
        </ul>
      </li>
    </ol>
    <p><strong parentName="p">{`Catatan`}</strong>{` : Ketika Dokumen Terima Pembayaran ini di Confirm maka akan otomatis membentuk Jurnal Tipe Bank / Kas dengan status Posted.`}</p>
    <h2 {...{
      "id": "paybill-bayar-tagihan"
    }}>{`PayBill (Bayar Tagihan)`}</h2>
    <p>{`PayBill adalah dokumen untuk melakukan pembayaran atas Vendor Bill yang statusnya “Open”. `}</p>
    <p>{`Formulir Terima Pembayaran`}</p>
    <img src='/public/img/BoC Trx/Form Paybill.png' />
    <h3 {...{
      "id": "kolom-kolom-pada-bayar-tagihan"
    }}>{`Kolom-kolom Pada Bayar Tagihan`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Vendor`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field Pilih Nama Vendor`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl. Pembayaran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk tanggal Dokumen Terima Pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Bayar Dari`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field Pilih Bank/Kas yang digunakan untuk tarnsaksi`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Nomor Referensi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan nomor referensi dokumen lain`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "detail-tabel-customer-invoice-1"
    }}>{`Detail Tabel Customer Invoice`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Bill#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk menampilkan Nomor Bill yang akan dilakukan pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl Jatuh Tempo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menampilkan Tanggal Jatuh Tempo masing-masing Bill`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Sisa Tagihan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menampilkan Nominal harga yang belum dibayarkan untuk masing-masing Bill`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pembayaran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk Memasukkan Jumlah pembayaran terhadap masing-masing Bill (Nominal pembayaran tidak boleh lebih dari sisa tagihan)`}</td>
        </tr>
      </tbody>
    </table>
    <h3 {...{
      "id": "informasi-lainnya-pada-customer-invoice-1"
    }}>{`Informasi Lainnya Pada Customer Invoice`}</h3>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Memo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambah keterangan atau catatan pada Invoice`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lampiran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambahkan file Attachment dalam dokumen Terima Pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Log`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mencatat setiap aktivitas pada dokumen Terima Pembayaran`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "membuat-dokumen-bayar-tagihan"
    }}>{`Membuat Dokumen Bayar Tagihan`}</h2>
    <p>{`Dokumen Bayar Tagihan dapat dibuat melalui dua cara :`}</p>
    <ol>
      <li parentName="ol">
        <p parentName="li">{`Melalui Bill yang akan dilakukan pembayaran, berikut merupakan langkah-langkahnya`}</p>
        <ul parentName="li">
          <li parentName="ul">{`Masuk ke List Vendor Bill dengan Klik Hutang Pada Side Menu > Vendor Bill`}</li>
          <li parentName="ul">{`Cari Vendor Bill yang akan dilakukan pembayaran (Bill Harus berstatus Open)`}</li>
          <li parentName="ul">{`Pada Detail Bill klik tombol PayBill`}</li>
          <li parentName="ul">{`Halaman akan terdireksi ke Halaman pembuatan dokumen Bayar Tagihan > Masukkan Jumlah Pembayarannya`}</li>
          <li parentName="ul">{`Jika sudah isi setiap field > klik tombol Save`}</li>
          <li parentName="ul">{`Untuk menuntaskan pembayaran dapat melakukan Konfirmasi Dokumen dengan Klik Tombol Confirm`}</li>
        </ul>
      </li>
      <li parentName="ol">
        <p parentName="li">{`Melalui Create manual dari halaman List Bayar Tagihan, berikut merupakan langkah-langkahnya :`}</p>
        <ul parentName="li">
          <li parentName="ul">
            <p parentName="li">{`Masuk ke List Bayar Tagihan dengan Klik Hutang Pada Side Menu > Bayar Tagihan > Pada List Bayar Tagihan Klik Tombol “Buat”`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Isi Formulir Terima Pembayaran seperti gambar berikut :`}</p>
  <ol type="a">
      <li>Pilih Nama Vendor yang akan melakukan pembayaran (Ketika memilih Vendor akan muncul pop up yang berisi list invoice yang dapat dilakukan pembayaran)</li>
      <li>Pilih Bill yang akan dibayarkan kepada Vendor</li>
      <li>Atur Tanggal diterbitkannya dokumen Bayar Tagihan</li>
      <li>Pilih Bank/Kas pada field Bayar Dari</li>
      <li>Masukkan Jumlah Pembayaran untuk setiap Bill</li>
      <li>Dalam satu dokumen Bayar Tagihan dapat mengandung lebih dari satu Bill, sehingga bisa klik tombol “Tambah Item” untuk menambah Bill yang lain</li>
      <li>Tambahkan file Attachment apabila diperlukan</li>
  </ol>
  <img src='/public/img/BoC Trx/Bikin Paybill.png' />
          </li>
          <li parentName="ul">
            <p parentName="li">{`Setelah semua field diisi, klik tombol Save untuk menyimpan dokumen Bayar Tagihan yang telah diisi sebelumnya. Pada saat ini status Dokumen adalah “Draft”. (Dokumen masih perlu Approval untuk dapat dilanjutkan ke langkah berikutnya)`}</p>
          </li>
          <li parentName="ul">
            <p parentName="li">{`Untuk melakukan Approval user dapat klik tombol Confirm pada halaman detail dokumen. Dengan ini status dokumen Bayar Tagihan menjadi “Done” yang artinya Pembayaran telah berhasil dilakukan.`}</p>
          </li>
        </ul>
      </li>
    </ol>
    <p><strong parentName="p">{`Catatan`}</strong>{` : Ketika Dokumen Bayar Tagihan ini di Confirm maka akan otomatis membentuk Jurnal Tipe Bank / Kas dengan status Posted.`}</p>
    <h2 {...{
      "id": "driver-settlement-pengeluaran-supir"
    }}>{`Driver Settlement (Pengeluaran Supir)`}</h2>
    <p>{`Driver Settlement adalah dokumen yang digunakan untuk melakukan pembayaran kepada supir karena telah menyelesaikan pengiriman Sales Order. `}</p>
    <h3 {...{
      "id": "formulir-driver-settlement"
    }}>{`Formulir Driver Settlement`}</h3>
    <img src='/public/img/BoC Trx/Form Driver Settlement.png' />
    <h4 {...{
      "id": "kolom-kolom-pada-driver-settlement"
    }}>{`Kolom-kolom Pada Driver Settlement`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Bayar Dari`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field Pilih Bank/Kas`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl. Pembayaran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk tanggal Dokumen Driver Settlement`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Nomor Referensi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan nomor referensi dokumen lain`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "detail-tabel-driver-settlement"
    }}>{`Detail Tabel Driver Settlement`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Supir`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom Pilih Nama Supir`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`SO#`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom Pilih Nomor SO (List SO yang tampil adalah semua SO yang telah diselesaikan oleh supir yang dipilih)`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Terbayar`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Menampilkan Nominal uang telah dibayarkan kepada supir`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tambah/Kurang`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk Memasukkan Jumlah pembayaran kepada supir atas satu SO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk mengetahui total uang yang diterima supir setelah ditambah/dikurangi atas satu SO`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Keterangan`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk memberikan catatan atas pembayaran`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "informasi-lainnya-pada-driver-settlement"
    }}>{`Informasi Lainnya Pada Driver Settlement`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Memo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambah keterangan atau catatan pada Dokumen Driver Settlement`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lampiran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambahkan file Attachment dalam dokumen Terima Pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menghitung total dari kolom Tambah/Kurang setiap line di dokumen Driver Settlement`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Log`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Mencatat setiap aktivitas pada dokumen Terima Pembayaran`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "membuat-dokumen-driver-settlement"
    }}>{`Membuat Dokumen Driver Settlement`}</h2>
    <p>{`Berikut merupakan langkah-langkah dalam pembuatan dokumen Driver Settlement :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Driver Settlement dengan klik Bank & Kas Pada Side Menu > Driver Settlement > Pada list Driver Settlement klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Isi Formulir Driver Settlement seperti gambar berikut :`}</p>
  <ol type="1">
  <li>Pilih Bank/Kas yang akan digunakan untuk transaksi</li>
  <li>Atur tanggal diterbitkannya dokumen Driver Settlement</li>
  <li>Pilih Supir</li>
  <li>Pilih SO (List SO muncul setelah Kolom Supir diisi)</li>
  <li>Masukkan Jumlah pembayaran kepada supir atas SO yang dipilih</li>
  <li>Tambahkan keterangan jika diperlukan</li>
  <li>Tambahkan file Attachment apabila diperlukan</li>
  </ol>
  <img src='/public/img/BoC Trx/Form Receive Payment.png' />
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi, klik tombol Save untuk menyimpan dokumen Driver Settlement yang telah diisi sebelumnya. Pada saat ini status Dokumen adalah “Draft”. (Dokumen masih perlu Approval untuk dapat dilanjutkan ke langkah berikutnya)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Untuk melakukan Approval user dapat klik tombol Confirm pada halaman detail dokumen. Dengan ini status dokumen Driver Settlement menjadi “Done” yang artinya Pembayaran telah berhasil dilakukan.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Catatan`}</strong>{` : Ketika Dokumen Driver Settlement ini di Confirm maka akan otomatis membentuk Jurnal Tipe Bank / Kas dengan status Posted.`}</p>
    <h2 {...{
      "id": "send-money-kirim-uang"
    }}>{`Send Money (Kirim Uang)`}</h2>
    <p>{`Send Money adalah dokumen yang digunakan untuk melakukan pembayaran atas sesuatu yang `}</p>
    <h3 {...{
      "id": "formulir-send-money"
    }}>{`Formulir Send Money`}</h3>
    <img src='/public/img/BoC Trx/Form Kirim Uang.png' />
    <h4 {...{
      "id": "kolom-kolom-pada-send-money"
    }}>{`Kolom-kolom Pada Send Money`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Bayar Dari`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field Pilih Bank/Kas`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl. Pembayaran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk tanggal Dokumen Kirim Uang`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Nomor Referensi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan nomor referensi dokumen lain`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "detail-tabel-send-money"
    }}>{`Detail Tabel Send Money`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Akun`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom Pilih CoA yang akan menerima uang dari Bank/Kas yang dipilih pada field Bayar Dari`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Deskripsi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk menambahkan catatan untuk setiap line CoA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Partner`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk pilih Nama Partner yang akan menerima Uang`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk Pilih Pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pembayaran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk memasukkan jumlah uang yang akan di kirim pada CoA tertentu`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "informasi-lainnya-pada-send-money"
    }}>{`Informasi Lainnya Pada Send Money`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Memo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambah keterangan atau catatan pada Dokumen Send Money`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lampiran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambahkan file Attachment dalam dokumen Terima Pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total daripada pembayaran setelah diberi pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Subtotal`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total pembayaran sebelum diberi pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total pajak secara keseluruhan`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "membuat-dokumen-send-money"
    }}>{`Membuat Dokumen Send Money`}</h2>
    <p>{`Berikut merupakan langkah-langkah dalam pembuatan dokumen Send Money  :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Send Money dengan klik Bank & Kas Pada Side Menu > Send Money > Pada list Send Money klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Isi Formulir Send Money seperti gambar berikut :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Pilih Bank/Kas yang akan digunakan untuk transaksi`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Atur tanggal diterbitkannya dokumen Send Money`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih CoA`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Tambahkan catatan untuk masing-masing line jika diperlukan`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih Nama Partner`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Tambahkan Pajak jika diperlukan`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan nominal uang yang akan dikirim ke CoA yang dipilih`}</p>
            <img src='/public/img/BoC Trx/Bikin Kirim Uang.png' />
          </li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi, klik tombol Save untuk menyimpan dokumen Send Money yang telah diisi sebelumnya. Pada saat ini status Dokumen adalah “Draft”. (Dokumen masih perlu Approval untuk dapat dilanjutkan ke langkah berikutnya)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Untuk melakukan Approval user dapat klik tombol Confirm pada halaman detail dokumen. Dengan ini status dokumen Send Money menjadi “Done” yang artinya Pembayaran telah berhasil dilakukan.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Catatan`}</strong>{` : Ketika Dokumen Send Money ini di Confirm maka akan otomatis membentuk Jurnal Tipe Bank / Kas dengan status Posted.`}</p>
    <h2 {...{
      "id": "receive-money-terima-uang"
    }}>{`Receive Money (Terima Uang)`}</h2>
    <p>{`Send Money adalah dokumen yang digunakan untuk melakukan pembayaran atas sesuatu yang `}</p>
    <h4 {...{
      "id": "formulir-send-money-1"
    }}>{`Formulir Send Money`}</h4>
    <img src='/public/img/BoC Trx/Form Kirim Uang.png' />
    <h4 {...{
      "id": "kolom-kolom-pada-receive-money"
    }}>{`Kolom-kolom Pada Receive Money`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Setor Ke`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field Pilih Bank/Kas`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl. Pembayaran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk tanggal Dokumen Terima Uang`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Referensi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan nomor referensi dokumen lain`}</td>
        </tr>
      </tbody>
    </table>
    <h5 {...{
      "id": "detail-tabel-receive-money"
    }}>{`Detail Tabel Receive Money`}</h5>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Akun`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom Pilih CoA yang akan mengirim uang dari Bank/Kas yang dipilih pada field Bayar Dari`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Deskripsi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk menambahkan catatan untuk setiap line CoA`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Partner`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk pilih Nama Partner yang akan mengirim Uang`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk Pilih Pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pembayaran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Kolom untuk memasukkan jumlah uang yang akan diterima dari CoA tertentu`}</td>
        </tr>
      </tbody>
    </table>
    <h4 {...{
      "id": "informasi-lainnya-pada-receive-money"
    }}>{`Informasi Lainnya Pada Receive Money`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Memo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambah keterangan atau catatan pada Dokumen Receive Money`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lampiran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambahkan file Attachment dalam dokumen Terima Pembayaran`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Total`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total daripada pembayaran setelah diberi pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Subtotal`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total pembayaran sebelum diberi pajak`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Pajak`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Total pajak secara keseluruhan`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "membuat-dokumen-receive-money"
    }}>{`Membuat Dokumen Receive Money`}</h2>
    <p>{`Berikut merupakan langkah-langkah dalam pembuatan dokumen Receive Money  :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Send Money dengan klik Bank & Kas Pada Side Menu > Receive Money > Pada list Receive Money klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Isi Formulir Receive Money seperti gambar berikut :`}</p>
        <ol parentName="li">
          <li parentName="ol">
            <p parentName="li">{`Pilih Bank/Kas yang akan digunakan untuk transaksi`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Atur tanggal diterbitkannya dokumen Receive Money`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih CoA`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Tambahkan catatan untuk masing-masing line jika diperlukan`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Pilih Nama Partner`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Tambahkan Pajak jika diperlukan`}</p>
          </li>
          <li parentName="ol">
            <p parentName="li">{`Masukkan nominal uang yang akan dikirim ke CoA yang dipilih`}</p>
            <img src='/public/img/BoC Trx/Bikin Terima Uang.png' />
          </li>
        </ol>
      </li>
    </ul>
    <ul>
      <li parentName="ul">{`Setelah semua field diisi, klik tombol Save untuk menyimpan dokumen Receive Money yang telah diisi sebelumnya. Pada saat ini status Dokumen adalah “Draft”. (Dokumen masih perlu Approval untuk dapat dilanjutkan ke langkah berikutnya)`}</li>
      <li parentName="ul">{`Untuk melakukan Approval user dapat klik tombol Confirm pada halaman detail dokumen. Dengan ini status dokumen Receive Money menjadi “Done” yang artinya Pembayaran telah berhasil dilakukan.`}</li>
    </ul>
    <p><strong parentName="p">{`Catatan`}</strong>{` : Ketika Dokumen Receive Money ini di Confirm maka akan otomatis membentuk Jurnal Tipe Bank / Kas dengan status Posted.`}</p>
    <h2 {...{
      "id": "transfer-uang"
    }}>{`Transfer Uang`}</h2>
    <p>{`Dokumen Transfer Uang biasanya digunakan sebagai dokumen transaksi internal transfer antar Bank/Kas yang ada pada suatu perusahaan`}</p>
    <h4 {...{
      "id": "formulir-transfer-uang"
    }}>{`Formulir Transfer Uang`}</h4>
    <img src='/public/img/BoC Trx/Form Internal Transfer.png' />
    <h4 {...{
      "id": "kolom-kolom-pada-transfer-uang"
    }}>{`Kolom-kolom Pada Transfer Uang`}</h4>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`Field`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`Description`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Transfer Dari`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field Pilih Bank/Kas yang akan mengirimkan Uang`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Transfer Ke`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field Pilih Bank/Kas yang akan menerima Uang`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Tgl. Setor`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk tanggal Dokumen Transfer Uang`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Referensi`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan nomor referensi dokumen lain`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Amount`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk memasukkan Nominal yang akan transfer antar Bank/Kas`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Memo`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambah keterangan atau catatan pada dokumen Transfer Uang`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}><strong parentName="td">{`Lampiran`}</strong></td>
          <td parentName="tr" {...{
            "align": null
          }}>{`Field untuk menambahkan file Attachment dalam dokumen Terima Pembayaran`}</td>
        </tr>
      </tbody>
    </table>
    <h2 {...{
      "id": "membuat-dokumen-transfer-uang"
    }}>{`Membuat Dokumen Transfer Uang`}</h2>
    <p>{`Berikut merupakan langkah-langkah dalam pembuatan dokumen Transfer Uang  :`}</p>
    <ul>
      <li parentName="ul">
        <p parentName="li">{`Buka Formulir Transfer Uang dengan klik Bank & Kas Pada Side Menu > Transfer Uang > Pada list Transfer Uang klik Tombol Create.`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Isi Formulir Transfer Uang seperti gambar berikut :`}</p>
        <ol parentName="li">
          <li parentName="ol">{`Pilih Bank/Kas yang akan digunakan sebagai pengirim uang`}</li>
          <li parentName="ol">{`Pilih Bank/Kas yang akan digunakan sebagai penerima uang`}</li>
          <li parentName="ol">{`Atur tanggal diterbitkannya dokumen Transfer Uang`}</li>
          <li parentName="ol">{`Masukkan Nominal uang yang akan di transfer`}</li>
          <li parentName="ol">{`Tambahkan catatan untuk dokumen Transfer Uang jika diperlukan`}</li>
          <li parentName="ol">{`Tambahkan Lampiran jika diperlukan`}</li>
        </ol>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Setelah semua field diisi, klik tombol Save untuk menyimpan dokumen Transfer Uang yang telah diisi sebelumnya. Pada saat ini status Dokumen adalah “Draft”. (Dokumen masih perlu Approval untuk dapat dilanjutkan ke langkah berikutnya)`}</p>
      </li>
      <li parentName="ul">
        <p parentName="li">{`Untuk melakukan Approval user dapat klik tombol Confirm pada halaman detail dokumen. Dengan ini status dokumen Transfer Uang menjadi “Done” yang artinya Pembayaran telah berhasil dilakukan.`}</p>
      </li>
    </ul>
    <p><strong parentName="p">{`Catatan`}</strong>{` : Ketika Dokumen Transfer Uang ini di Confirm maka akan otomatis membentuk Jurnal Tipe Bank / Kas dengan status Posted.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      